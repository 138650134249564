import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import DecoratingInnerMidContent from '../component/DecoratingInnerMidContent/DecoratingInnerMidContent'
import { Helmet } from 'react-helmet'

function HomeOwnerDecorating() {
    return (
        <>
          <Helmet>
            <title> 
            Home Decoration Service in Canada
            </title>
            <meta 
            name='description' 
            content="Make your space with MES Décors' expert home decoration service in Canada. Discover stylish, modern home decoration tailored to your unique taste." 
            />
<link rel="canonical" href="https://www.mesdecors.ca/decorating-home-owner" />

            </Helmet>
        
            <HeroBanner 
                subHeading=""
                Heading="DECORATING SERVICE FOR HOME OWNER"
                // Heading="Transform your house into a home "
                // spanHeading="WITH MES Décors"
                Paragraph="From cozy corners to stylish accents, let us bring your vision to life with our personalized decorating services."
                bgImg="/images/creatives/decorating-home-owner-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
            <TextContent
                subHeading=""
                heading= "HOW DOES THE DECORATING SERVICE WORK?"
                content={`Home decorating is more than just adding pretty touches to your space; it's about creating an environment that enhances your lifestyle and reflects your personality. At MES Décors, we believe in the transformative power of design. From boosting mood and productivity to increasing property value, the benefits of home decorating are endless. Our expert team is here to show you firsthand how thoughtful design choices can elevate your home and improve your quality of life. Say goodbye to bland and hello to beautiful with MES Décors by your side.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/decorating-home-owner-text-1.png"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />

            <DecoratingInnerMidContent
                heading="A BROAD SET OF DESIGN"
                content="Discover the art of interior design with MES Décors. Our comprehensive approach encompasses everything from colour palettes to furniture arrangement, ensuring every aspect of your space harmonizes seamlessly. With a keen eye for detail and a passion for creativity, we turn ordinary rooms into extraordinary living spaces. From contemporary chic to timeless elegance, our diverse range of interior design services caters to every taste and style. Let us unleash the full potential of your home and create a space that truly reflects who you are."
                img1="/images/creatives/decorating-home-owner-text-2.jpg"
                img2="/images/creatives/decorating-home-owner-text-3.jpg"
            />

            <TextContent
                subHeading=""
                heading= "THE ART OF HOME DECORATING"
                content={`Walk on a journey of creativity and expression with MES Décors as your guide. Our passion for the art of home decorating drives us to craft spaces that inspire and delight. From selecting the perfect color palette to curating unique decor pieces, we infuse every project with a touch of artistry and sophistication. \n At MES Décors, we believe that home decorating is more than just arranging furniture; it's about creating an atmosphere that resonates with your soul. With our expert eye for design and commitment to excellence, we transform houses into havens, bringing beauty and joy to every corner. Let us set free the artist within and turn your home into a masterpiece of style and comfort.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/decorating-home-owner-text-4.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
            />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default HomeOwnerDecorating